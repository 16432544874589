@import url('https://fonts.googleapis.com/css2?family=Bungee+Hairline&family=Bungee+Outline&family=Codystar:wght@300;400&family=Gajraj+One&family=Rubik+80s+Fade&family=Silkscreen:wght@400;700&family=VT323&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {

    --clr-background: #160724;
    --clr-greyaczent: #888888;
    --clr-benefits: #260E37;
    --clr-neongreen: #39FF14
}
